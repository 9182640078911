
import PrimaryButton from "@/components/buttons/PrimaryButton.vue"
import TertiaryButton from "@/components/buttons/TertiaryButton.vue"
import Service from "@/services/Service"
import InputText from "primevue/inputtext"
import { ref, watch } from "vue"
import { useRouter } from "vue-router"

export default {
  name: "NewEmailTemplate",
  props: {
    emailType: {
      type: Object,
    },
    language: {
      type: String,
    },
    endpoints: {
      type: Object,
    },
    service: {
      type: Object,
    },
    previousRouteName: {
      type: String,
    },
    organizationId: {
      type: String,
      default: null,
    },
    users: {
      type: Array,
      default: null,
    },
    text: {
      type: Object,
      required: true,
    },
  },
  setup(props: any) {
    const alias = ref("")
    const userSpecific = ref(false)
    const router = useRouter()
    const userId = ref(null)
    const computerId = ref(null)
    const selectedUser = ref(null)
    function saveEmailTemplate() {
      const body = {
        alias: alias.value,
        language: props.language,
        title: "",
        body: "",
        type: props.emailType.value,
      }
      let user
      if (props.organizationId) {
        user = selectedUser.value ? selectedUser.value.id : null
      } else {
        user = userSpecific.value
      }
      props.service
        .postMailTemplate(
          props.endpoints.post.url,
          props.organizationId,
          computerId.value,
          user,
          body
        )
        .then((res: any) => {
          router.push({
            name: props.previousRouteName,
            params: {
              language: props.language,
              emailType: props.emailType,
              newlyAddedTemplateId: res.data,
            },
          })
        })
    }
    function onCancel() {
      router.go(-1)
    }
    watch(
      () => userSpecific.value,
      (newValue, oldValue) => {
        selectedUser.value = null
      }
    )
    return {
      alias,
      saveEmailTemplate,
      userSpecific,
      selectedUser,
      onCancel,
    }
  },
  components: { PrimaryButton, TertiaryButton },
}
