import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8b333094"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex justify-content-center" }
const _hoisted_2 = { class: "new-email-template" }
const _hoisted_3 = { class: "mb-4" }
const _hoisted_4 = { class: "flex align-items-center mb-3" }
const _hoisted_5 = { for: "alias" }
const _hoisted_6 = {
  key: 0,
  class: "flex mb-3"
}
const _hoisted_7 = {
  class: "checkbox-label",
  for: "visibleForMe"
}
const _hoisted_8 = {
  key: 1,
  class: "flex flex-column"
}
const _hoisted_9 = { class: "flex align-items-center mb-3" }
const _hoisted_10 = {
  for: "specificUser",
  class: "checkbox-label"
}
const _hoisted_11 = { class: "flex justify-content-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_PrimaryButton = _resolveComponent("PrimaryButton")!
  const _component_TertiaryButton = _resolveComponent("TertiaryButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, _toDisplayString($props.text.newEmailTemplateTitle), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("label", _hoisted_5, _toDisplayString($props.text.alias) + ":", 1),
        _createVNode(_component_InputText, {
          modelValue: $setup.alias,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.alias) = $event)),
          id: "alias",
          placeholder: $props.text.alias
        }, null, 8, ["modelValue", "placeholder"])
      ]),
      (!$props.users)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_Checkbox, {
              modelValue: $setup.userSpecific,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.userSpecific) = $event)),
              binary: true,
              inputId: "visibleForMe",
              class: "mr-2"
            }, null, 8, ["modelValue"]),
            _createElementVNode("label", _hoisted_7, _toDisplayString($props.text.onlyVisibleForMe), 1)
          ]))
        : _createCommentVNode("", true),
      ($props.users)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_Checkbox, {
                modelValue: $setup.userSpecific,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.userSpecific) = $event)),
                binary: true,
                inputId: "specificUser",
                class: "mr-2"
              }, null, 8, ["modelValue"]),
              _createElementVNode("label", _hoisted_10, _toDisplayString($props.text.forSpecificUser), 1)
            ]),
            ($setup.userSpecific)
              ? (_openBlock(), _createBlock(_component_Dropdown, {
                  key: 0,
                  modelValue: $setup.selectedUser,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.selectedUser) = $event)),
                  options: $props.users,
                  optionLabel: "username",
                  placeholder: $props.text.selectUser,
                  class: "mb-3"
                }, null, 8, ["modelValue", "options", "placeholder"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_PrimaryButton, {
          onClick: $setup.saveEmailTemplate,
          label: $props.text.save
        }, null, 8, ["onClick", "label"]),
        _createVNode(_component_TertiaryButton, {
          onClick: $setup.onCancel,
          label: $props.text.cancel
        }, null, 8, ["onClick", "label"])
      ])
    ])
  ]))
}